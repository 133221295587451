import React, { useRef, useState, memo } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

const ContactUs = memo(() => {
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const nameRef = useRef(null);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [sendingUpdate, setSendingUpdate] = useState(false);

  const sendEmail = async (e) => {
    await setSendingUpdate(true);
    e.preventDefault();
    try {
      if (validate()) {
        await emailjs
          .send(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            {
              name: name,
              phone: phone,
              user_email: email,
              message: message,
            },
            process.env.REACT_APP_PUBLIC_KEY
          )
          .then(
            (result) => {
              alert("Message sent successfully!");
              setName("");
              setPhone("");
              setEmail("");
              setMessage("");
            },
            (error) => {
              alert("Failed to send message, please try again.");
            }
          );
      }
    } finally {
      setSendingUpdate(false);
    }
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = name ? "" : "Name is required";
    tempErrors.phone = phone ? "" : "Phone is required";
    tempErrors.email = email ? "" : "Email is required";
    tempErrors.message = message ? "" : "Message is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };
  const { t } = useTranslation();
  return (
    <Element name="contact">
      <Box
        sx={{
          maxWidth: "100vw",
          // mt: { md: 10 },
          position: "relative",
          pr: { xs: 2, sm: 8, md: 10, lg: 30 },
          // py: 3,
          pl: { xs: 2, sm: 8, md: 10 },
          flex: 1,
        }}
      >
        <Box sx={{ py: { xs: 2, sm: 3, md: 5 } }}>
          <Typography
            variant="h4"
            fontWeight={400}
            mb={1}
            noWrap
            sx={{
              fontSize: {
                xs: "1.2rem", // For extra-small screens (e.g., mobile)
                sm: "1.5rem", // For small screens (e.g., tablets)
                md: "1.8rem", // For medium screens (e.g., small laptops)
              },
              // p: 0,
            }}
          >
            {t("contact_description")}
          </Typography>
          <Box
            sx={{
              width: "20vh",
              background: "#25c5dc",
              height: "0.5vh",
              borderRadius: "10px",
            }}
          />
        </Box>
        <Box noValidate autoComplete="off">
          {/* <Grid container columnSpacing={1.5}>
            <Grid item xs={12} sm={6}> */}
          <TextField
            inputRef={nameRef}
            id="name"
            label="Name*"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
            fullWidth
            margin="normal"
            size="small"
            InputProps={{
              style: { color: "black", borderColor: "black" },
            }}
            InputLabelProps={{ style: { color: "rgba(0, 0, 0, 0.7)" } }}
          />
          {/* </Grid>
            <Grid item xs={12} sm={6}> */}
          <TextField
            inputRef={phoneRef}
            id="phone"
            label="Phone*"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            error={!!errors.phone}
            helperText={errors.phone}
            fullWidth
            margin="normal"
            size="small"
            InputProps={{
              style: { color: "black", borderColor: "black" },
            }}
            InputLabelProps={{ style: { color: "rgba(0, 0, 0, 0.7)" } }}
          />
          {/* </Grid>
          </Grid> */}
          <TextField
            inputRef={emailRef}
            id="email"
            label="Email*"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
            fullWidth
            margin="normal"
            size="small"
            InputProps={{
              style: { color: "black", borderColor: "black" },
            }}
            InputLabelProps={{ style: { color: "rgba(0, 0, 0, 0.7)" } }}
          />
          <TextField
            inputRef={messageRef}
            id="message"
            label="Message*"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            error={!!errors.message}
            helperText={errors.message}
            multiline
            rows={4}
            fullWidth
            margin="normal"
            size="small"
            InputProps={{
              style: { color: "black", borderColor: "black" },
            }}
            InputLabelProps={{ style: { color: "rgba(0, 0, 0, 0.7)" } }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={sendEmail}
            fullWidth
            sx={{ mt: 2 }}
            disabled={sendingUpdate}
            startIcon={sendingUpdate ? <CircularProgress size={24} /> : null}
          >
            {sendingUpdate ? "" : "Submit"}
          </Button>
        </Box>
      </Box>
    </Element>
  );
});

export default ContactUs;
