import React from "react";
import { Box, Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        position: "relative",
        bottom: 0,
        bgcolor: "#2a3a42", // Dark background color similar to the image
        color: "#b0c4cc", // Light grey text color similar to the image
        textAlign: "center",
        py: 1.5,
        px: 2,
        fontSize: "0.875rem",
        borderTop: "1px solid rgba(255, 255, 255, 0.2)",
      }}
    >
      <Typography variant="body2" component="div">
        © Copyright company 2024. All Right Reserved. |
        <Link
          href="https://www.company.com.au" // Replace with actual link
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
          sx={{ color: "#d0e2ed", ml: 0.5 }} // Link color close to the image
        >
          Our Official Australia Website
        </Link>
      </Typography>
      <Typography
        variant="body2"
        sx={{ mt: 0.5, fontSize: "0.75rem", color: "#a9b9c1" }}
      >
        * All registration marks and logos are the property of their respective
        owners.
      </Typography>
    </Box>
  );
};

export default Footer;
