import React from "react";
import { Element } from "react-scroll";
import { Box, Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();

  const images = Array.from({ length: 3 }, (_, index) => ({
    src: require(`/src/assets/consulting-${index + 1}.jpg`),
  }));

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      {/* Fullscreen Image Carousel */}
      <Element name="home">
        <Box sx={{ maxWidth: "100vw", position: "relative" }}>
          <Carousel
            showThumbs={true}
            showStatus={false}
            infiniteLoop
            autoPlay
            interval={5000}
            stopOnHover={false}
            showIndicators={false}
          >
            {images.map((image, index) => (
              <Box
                key={index}
                sx={{ position: "relative", width: "100vw", height: "70vh" }}
              >
                {/* Image */}
                <Box
                  component="img"
                  src={image.src}
                  alt={`Slide ${index + 1}`}
                  sx={{
                    width: "100vw",
                    objectFit: "cover",
                    height: "70vh",
                  }}
                />

                {/* Text Overlay */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: { xs: "center", md: "flex-start" },
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.4)", // Optional dark overlay
                    color: "white",
                    padding: { xs: 5, sm: 8, md: 10, lg: 30 }, // Responsive padding
                    // padding:{isTablet ? }
                    gap: 1, // Space between the lines
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: {
                        xs: "1.5rem",
                        sm: "2rem",
                        md: "3rem",
                        lg: "4.5rem",
                        xl: "5rem",
                      },
                      lineHeight: 1.2,
                    }}
                  >
                    {t(`home_heading_caption_${index + 1}`)}
                  </Typography>

                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: {
                        xs: "1rem",
                        sm: "1.25rem",
                        md: "2rem",
                        lg: "2.5rem",
                        xl: "3rem",
                      },
                      lineHeight: 1.4,
                      mt: 1, // Adds a margin above to separate it from the heading
                    }}
                  >
                    {t(`home_heading_description_${index + 1}`)}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Carousel>
        </Box>
      </Element>
    </Box>
  );
}

export default Home;
